import {Box, Breadcrumbs, Container, FormControlLabel, Link, Switch, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import SimpleSettings from "./SimpleSettings";
import AdvanceSettings from "./AdvanceSettings";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box >{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SettingsMenu = () => {
    const [value, setValue] = useState(0);
    const [isAdvance, setIsAdvance] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
            <Container sx={{mt: 12}} maxWidth={"lg"}>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href={"/settings"}
                        >
                            <Typography sx={{color: 'text.primary'}}>Settings</Typography>
                        </Link>
                    </Breadcrumbs>
                </div>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

                    <Typography sx={{my: 2}} color={"textPrimary"} variant={"h4"}>Settings</Typography>
                    <FormControlLabel
                        control={<Switch
                            checked={isAdvance}
                            onChange={() => setIsAdvance(!isAdvance)}
                        />}
                        label={<Typography variant="body2" color={"textSecondary"}>Developer</Typography>}
                        labelPlacement="start"
                    />
                </Box>

                <Typography color={"textSecondary"} variant={'subtitle1'}>Customize your
                    Experience</Typography>
                <Box sx={{my:2}}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Video " {...a11yProps(0)} />
                        {isAdvance && <Tab label="Client " {...a11yProps(1)} />}
                        <Tab label="Input" {...a11yProps(isAdvance ? 2 : 1)} />
                        <Tab label="Gamepad" {...a11yProps(isAdvance ? 3 : 2)} />
                        <Tab label="Hot Keys" {...a11yProps(isAdvance ? 4 : 3)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {isAdvance ? <AdvanceSettings settingType='video'/> : <SimpleSettings settingType='video'/>}
                </CustomTabPanel>
                {isAdvance && (
                    <CustomTabPanel value={value} index={1}>
                        <AdvanceSettings settingType='client'/>
                    </CustomTabPanel>
                )}
                <CustomTabPanel value={value} index={isAdvance ? 2 : 1}>
                    <AdvanceSettings settingType='input'/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={isAdvance ? 3 : 2}>
                    <AdvanceSettings settingType='gamepad'/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={isAdvance ? 4 : 3}>
                    Item Three
                </CustomTabPanel>
            </Container>
    );
}

export default SettingsMenu;
