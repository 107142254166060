import apiLinks, {getRefreshToken, setAuthTokens} from "./apiLinks";

const logout = async () => {
  if (getRefreshToken() === "") {
    return {
      success: true,
    }
  }
  const resp = await fetch(apiLinks.authV1.logout, {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify({
      refresh_token: getRefreshToken()
    }),
  })
  if (resp.status === 200) {
    setAuthTokens({})
    return {
      success: true
    }
  } else {
    let data = await resp.json()
    setAuthTokens({})
    return {
      success: false,
      data
    }
  }
}
export default logout