import apiLinks, {getAuthToken} from "./apiLinks";
import refreshToken, {isTokenExpired} from "./refresh";

const auth = async () => {
  const authToken = getAuthToken();
  const resp = await fetch(apiLinks.auth, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authToken,
    }
  })
  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await auth()
    }
    return {
      authenticated: false,
      user: undefined
    }
  }
  if (resp.status === 200) {
    const data = await resp.json();
    return {
      user: data,
      authenticated: true
    }
  }
}
export default auth;