import {alpha, createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      variants: [
        {
          props: {variant: 'elevation', type: 'glass'},
          style: ({theme}) => ({
            background: alpha(theme.palette.primary.dark, 0.1),
          })
        }
      ],
      defaultProps: {
        elevation: 10
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({theme}) => ({
          "&:has(> input:-webkit-autofill)": {
            backgroundColor: theme.palette.secondary.dark,
          },
          "& > input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.secondary.dark} inset`,
          },

        }),

      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    },
  }
})

export default theme;