import apiLinks, {getAuthToken} from "./apiLinks";
import {generatePath} from "react-router-dom";
import refreshToken, {isTokenExpired} from "./refresh";

export const getDevices = async () => {
  let resp = await fetch(apiLinks.device.base + apiLinks.device.list, {
    method: 'GET', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    },
  })
  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await getDevices()
    }
  }

  if (resp.status === 200) {
    return resp.json()
  } else {
    throw resp.status
  }
}
export const deleteDeviceApi = async (deviceId) => {
  let path = generatePath(apiLinks.device.delete, {device_id: deviceId})
  let resp = await fetch(apiLinks.device.base + path, {
    method: 'DELETE', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    }
  })

  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await deleteDeviceApi(deviceId)
    }
  }


  if (resp.status === 200) {
    return resp.json()
  } else {
    throw resp.status
  }
}
export const updateDeviceApi = async (deviceData) => {
  let path = generatePath(apiLinks.device.update, {device_id: deviceData.id})
  let resp = await fetch(apiLinks.device.base + path, {
    method: 'PUT', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    }, body: JSON.stringify(deviceData)
  })

  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await updateDeviceApi(deviceData)
    }
  }

  if (resp.status === 200) {
    return await resp.json()
  } else {
    throw resp.status
  }
}

export const getDeviceApi = async (deviceId) => {
  let path = generatePath(apiLinks.device.get, {device_id:deviceId})
  let resp = await fetch(apiLinks.device.base + path, {
    method: 'GET', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    }
  })

  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await getDeviceApi(deviceId)
    }
  }

  if (resp.status === 200) {
    return await resp.json()
  } else {
    throw resp.status
  }
}

export const addDeviceOtpApi = async (otp) => {
  let path = apiLinks.add_device
  let resp = await fetch(path, {
    method: 'POST', mode: 'cors', body: JSON.stringify({otp: otp}), headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    }
  })

  if (resp.status === 401) {
    if (await isTokenExpired(resp) && await refreshToken()) {
      return await addDeviceOtpApi(otp)
    }
  }

  if (resp.status === 200) {
    return await resp.json()
  } else {
    throw resp.status
  }
}