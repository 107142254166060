import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme';
import {Provider} from "react-redux";
import store from "./redux/store";
import {
  BrowserRouter as Router
} from 'react-router-dom';
import {Workbox} from 'https://storage.googleapis.com/workbox-cdn/releases/7.3.0/workbox-window.prod.mjs';
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <CssBaseline enableColorScheme/>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={"473054456182-e2es8fl94cnsjv05mjua6lebsm17ndf9.apps.googleusercontent.com"}>
        <Router>
          <App/>
        </Router>
      </GoogleOAuthProvider>
    </Provider>
  </ThemeProvider>
</React.StrictMode>);

// This code sample uses features introduced in Workbox v6.

if ('serviceWorker' in navigator && process.env.NODE_ENV !== "development") {
  try {
    const wb = new Workbox('/service-worker.js');

    const showSkipWaitingPrompt = async (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener('controlling', () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        window.location.reload();
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      // This code assumes your app has a promptForUpdate() method,
      // which returns true if the user wants to update.
      // Implementing this is app-specific; some examples are:
      // https://open-ui.org/components/alert.research or
      // https://open-ui.org/components/toast.research
      const updateAccepted = await promptForUpdate();

      if (updateAccepted) {
        wb.messageSkipWaiting();
      }
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', (event) => {
      showSkipWaitingPrompt(event);
    });

    wb.register();
  } catch (e) {
    console.log("running in dev mode")
  }

}

function promptForUpdate() {
  return new Promise((resolve, reject) => {
    let ev = new CustomEvent("update_available", {detail: resolve})
    window.dispatchEvent(ev)
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
