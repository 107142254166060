import apiLinks, {getRefreshToken, setAuthTokens} from "./apiLinks";

const refreshToken = async () => {
  let token = getRefreshToken();
  if (!token) {
    return false
  }
  const resp = await fetch(apiLinks.authV1.refreshToken, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: token,
    })
  })
  if (resp.status === 400) {
    const data = await resp.json()
    console.error(data)
    setAuthTokens({})
    return false
  }
  if (resp.status >= 200) {
    const data = await resp.json();
    setAuthTokens(data);
    return true
  }
}

const isTokenExpired = async (resp) => {
  if (resp.status === 401) {
    try {
      let errors = await resp.json()
      if (errors?.auth_token) {
        return true
      }
    } catch (error) {
      console.log("response is not json")
      console.error(error)
      return false
    }
  }
  return false
}
export {isTokenExpired}
export default refreshToken;