import apiLinks from "./apiLinks";

const reset_password = async (token, payload) => {
  const resp = await fetch(apiLinks.reset_password + "?k=" + encodeURIComponent(token), {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      password: payload.password, password_confirmation: payload.password_confirmation
    })
  })
  if (200 <= resp.status && resp.status < 300) {
    return {success: true}
  } else {
    const data = await resp.json();
    return {success: false, data}
  }
}

export default reset_password;