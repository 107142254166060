import React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Collapse,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";

const SettingsCard = ({
                          title,
                          description,
                          settings,
                          menuType,
                          menuItems = [], // Default to an empty array
                          menuOpen,
                          openMenu,
                          setSettingsValue,
                          sliderValue = 1, // Default slider value
                          scrollLimits = {min: 0, step: 1, max: 100} // Default scroll limits
                      }) => {
    // Ensure settings.displayValue exists and is valid
    if (!settings || settings.displayValue === undefined) {
        return null; // Do not render if settings are invalid
    }

    const handleSliderChange = (e, v) => {
        setSettingsValue(v);
    };

    const handleMenuItemClick = (e) => {
        setSettingsValue(e.target.value);
    };

    const handleTextChange = (e) => {
        setSettingsValue(e.target.value);
    };

    const handleCheckboxChange = (e) => {
        setSettingsValue(e.target.checked);
    };

    return  (
        <Card elevation={3} sx={{
            display: 'flex', justifyContent: 'space-between', my: 1, backgroundColor: 'transparent',
            backdropFilter: "blur(150px)"
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '60%', ml: 2}}>
                <CardContent sx={{flex: '2 0 auto'}}>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography gutterBottom sx={{color: 'text.secondary', fontSize: 14}}>
                        {description}
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                <CardActions sx={{p: 2, mr: 2, display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    {menuType === 'TEXT' ? (
                        <Box sx={{display: 'flex', alignItems: 'center', width: '60%', justifyContent: 'end'}}>
                            <TextField
                                variant="outlined"
                                value={settings.displayValue} // Default to empty string
                                onChange={handleTextChange}
                            />
                        </Box>
                    ) : menuType === 'CHECKBOX' ? (
                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                            <Box sx={{flexGrow: 1}}/>
                            <Checkbox
                                checked={settings.displayValue}
                                onChange={handleCheckboxChange}
                            />
                        </Box>
                    ) : menuType === 'MENU' ? (
                        <Select
                            sx={{display: 'flex', alignItems: 'center', width: '60%', justifyContent: 'end'}}
                            value={settings.displayValue}
                            onChange={handleMenuItemClick}
                        >
                            {menuItems.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Button
                            onClick={openMenu}
                            variant="outlined"
                            endIcon={<ArrowDropDown/>}
                            sx={{
                                display: 'flex', justifyContent: "space-between", width: '60%',
                                color: "white", textTransform: "none",
                                textAlign: "left",
                                p: 2,
                                border: "0.5px solid white",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    borderColor: "white",
                                }
                            }}
                        >
                            {settings.displayValue || 'None'}
                        </Button>
                    )}
                </CardActions>
                {menuType === 'SLIDER' ? (
                    <Collapse in={menuOpen} sx={{display: 'flex', alignItems: 'center', pr: 2}}>
                        <Box sx={{alignItems: 'center', width: '100%'}}>
                            <Slider
                                onChange={handleSliderChange}
                                valueLabelDisplay="auto"
                                min={scrollLimits.min}
                                step={scrollLimits.step}
                                max={scrollLimits.max}
                                value={sliderValue || 1} // Default to 1
                                sx={{width: "100%"}}
                            />
                        </Box>
                    </Collapse>
                ) : null}
            </Box>
        </Card>
    );
};

export default SettingsCard;
