import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStreamConfig } from "../../../redux/SettingsSlice";
import SettingsCard from "./SettingsCard";
import {setClientConfig} from "../../../redux/ClientSettingsSlice";

const SimpleSettings = ({settingType}) => {
    const dispatch = useDispatch();
    const streamSettingsStore = useSelector(state => state.settings);
    const storedClientSettings = useSelector(state => state.client_settings);
    const [streamSettings, setStreamSettings] = useState({});
    const [clientSettings, setClientSettings] = useState({});


    useEffect(() => {
        setStreamSettings(streamSettingsStore);
    }, [streamSettingsStore]);

    useEffect(() => {
        setClientSettings(storedClientSettings)
    }, [storedClientSettings])


    const [menuOpen, setMenuOpen] = useState("");

    const openMenu = (menuId) => (e) => {
        setMenuOpen(menuId);
    };

    const closeMenu = () => {
        setMenuOpen("");
    };
    const setClientSettingsValue = (name, value) => {
        const updatedSettings = {...clientSettings, [name]: value};
        setClientSettings(updatedSettings);
        dispatch(setClientConfig(updatedSettings));
        closeMenu();
    };
    const setStreamSettingsValue = (name, value) => {
        const updatedSettings = { ...streamSettings, [name]: value };
        setStreamSettings(updatedSettings);
        dispatch(setStreamConfig(updatedSettings));
        closeMenu();
    };

    const setMultipleSettingValue = (changedSettings) => {
        const updatedSettings = {...streamSettings, ...changedSettings};
        setStreamSettings(updatedSettings);
        dispatch(setStreamConfig(updatedSettings));
    }
    const handleVideoQualityPresetSettings = (_ , resolutionPresetValue) => {
        const resolution_map = new Map([
                ["HD", {width: 1280, height: 720}],
                ["FullHD", {width: 1920, height: 1080}],
                ["2K", {width: 2560, height: 1440}],
                ["4K", {width: 3840, height: 2160}]
            ]
        );
        const multiplierFactor = 1024 * 1024;
        const bitrate_map = new Map([
            ["HD", 10 * multiplierFactor],
            ["FullHD", 10 * multiplierFactor],
            ["2K", 15 * multiplierFactor],
            ["4K", 20 * multiplierFactor]]
        );
        setClientSettingsValue("resolution_preset", resolutionPresetValue);
        setMultipleSettingValue({
            "resolution": resolution_map.get(resolutionPresetValue),
            "bitrate": bitrate_map.get(resolutionPresetValue)
        })
    }

    const handleCodecPresetChange = (name , codec) => {
        if (codec === 'h264') {
            setMultipleSettingValue({"codec": codec, "codec_string": "avc1.64003E"})
        } else {
            setMultipleSettingValue({"codec": codec, "codec_string": "hev1.1.6.L93.B0"})
        }
        closeMenu();
    }

    const simpleMenu = {
        video: [
            {
                menuType: 'MENU',
                menuName: 'Video Resolution',
                menuDescription: "Switching between these settings allows you to customize your video experience based on your preferences and device capabilities",
                menuCurrValue: clientSettings.resolution_preset,
                menuItems: [
                    {label: "HD (720p)", value: "HD"},
                    {label: "FullHD (1080p)", value: "FullHD"},
                    {label: "2K (1440p)", value: "2K"},
                    {label: "4K (2160p)", value: "4K"},
                ],
                settingsName: 'resolution_preset',
                functionName: handleVideoQualityPresetSettings
            },
            {
                menuType: 'MENU',
                menuName: 'Framerate',
                menuDescription: "Control the number of frames per second that are displayed on a screen in a given amount of time",
                menuCurrValue: `${streamSettings.framerate || ''}`,
                menuItems: [
                    {label: "30 fps", value: 30},
                    {label: "60 fps", value: 60},
                    {label: "120 fps", value: 120},
                ],
                settingsName: 'framerate',
                functionName: setStreamSettingsValue
            },
            {
                menuType: 'MENU',
                menuName: 'Codec',
                menuDescription: "Select the video codec used for streaming, H264 better compatibility hevc efficient",
                menuCurrValue:  `${streamSettings.codec || ''}`,
                menuItems: [
                    {label: "H264", value: "h264"},
                    {label: "HEVC", value: "hevc"}
                ],
                settingsName: 'codec',
                functionName: handleCodecPresetChange
            }
        ],
        client : [{
            menuType: 'MENU',
            menuName: 'Hardware Acceleration',
            menuDescription: "Opt for hardware decoding to minimize latency and reduce resource consumption. Use software decoding only if necessary for compatibility",
            menuCurrValue: `${streamSettings.hardware_acceleration === 'prefer-hardware' ? 'Hardware' : 'Software'}`,
            menuItems: [
                {label: "Hardware", value: "prefer-hardware"},
                {label: "Software", value: "prefer-software"}
            ],
            settingsName: 'hardware_acceleration',
            functionName: setStreamSettingsValue
        }]
    }

    return (
        <>
            {simpleMenu[settingType].map(settingsCardOption => (
                settingsCardOption && settingsCardOption.menuCurrValue && <SettingsCard
                    key={settingsCardOption.menuName}
                    title={settingsCardOption.menuName}
                    description={settingsCardOption.menuDescription}
                    settings={{ displayValue: settingsCardOption.menuCurrValue }}
                    menuType={settingsCardOption.menuType}
                    menuItems={settingsCardOption.menuItems}
                    menuOpen={menuOpen === settingsCardOption.menuName}
                    openMenu={openMenu(settingsCardOption.menuName)}
                    setSettingsValue={(value) => settingsCardOption.functionName(settingsCardOption.settingsName, value)}
                />
            ))}
        </>
    );
};

export default SimpleSettings;
