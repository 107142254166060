const base_api = process.env.NODE_ENV === "development" ? '//' + window.location.hostname + ":5001/api" : '/api'
const base_ws = process.env.NODE_ENV === "development" ? getwsProtocol() + window.location.hostname + ':5002/api/client/wsV1' : getwsProtocol() + window.location.host + '/api/client/wsV1'
// const base_api = '//' + window.location.hostname + ":5001" + '/api'
// const base_ws = getwsProtocol() + window.location.hostname + ":5002" + '/api/client/_ws'

const oauth_base = base_api + '/oauth'
const auth_base = base_api + '/auth'
const authV1_base = base_api + '/auth/v1'

let authTokens;

const LOCALSTORE_KEY = "auth_tokens";

let storedSettings = window.localStorage.getItem(LOCALSTORE_KEY);
if (storedSettings) {
  authTokens = Object.assign({}, JSON.parse(storedSettings));
} else {
  authTokens = {};
}

const setAuthTokens = (tokens) => {
  authTokens = tokens;
  window.localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(authTokens));
}

const apiLinks = {
  auth: auth_base,
  signup: auth_base + '/signup',
  login: auth_base + '/login',
  logout: auth_base + '/logout',
  forgot_password: auth_base + '/forgot_password',
  reset_password: auth_base + '/reset_password',
  oauth_google: oauth_base + '/google',

  add_device: base_api + "/client/add_device_otp",

  authV1: {
    login: authV1_base + '/login',
    logout: authV1_base + '/logout',
    loginWithGoogle: authV1_base + '/g_login',
    refreshToken: authV1_base + '/refresh',
  },

  device: {
    base: base_api + "/devices",
    list: "",
    get: "/:device_id",
    update: "/:device_id",
    delete: "/:device_id"
  },

  ws: base_ws
}

const getRefreshToken = () => {
  return authTokens.refresh_token;
}
const getAuthToken = () => {
  return authTokens.auth_token;
}

export default apiLinks;

export {getAuthToken, getRefreshToken, setAuthTokens}

function getwsProtocol() {
  if (window.location.protocol === "https:") {
    return "wss://"
  }
  return "ws://"
}
