import apiLinks, {setAuthTokens} from "./apiLinks";

const loginWithGoogle = async (payload) => {
  const resp = await fetch(apiLinks.authV1.loginWithGoogle, {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (200 <= resp.status && resp.status < 300) {
    const data = await resp.json();
    setAuthTokens(data)
    return {success: true}
  } else {
    const data = await resp.json();
    return {success: false, data}
  }
}
export default loginWithGoogle;