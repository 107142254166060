import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStreamConfig} from "../../../redux/SettingsSlice";
import SettingsCard from "./SettingsCard";
import {setClientConfig} from "../../../redux/ClientSettingsSlice";

const AdvanceSettings = ({settingType}) => {
    const dispatch = useDispatch();
    const streamSettingsStore = useSelector(state => state.settings);
    const storedClientSettings = useSelector(state => state.client_settings);
    const [streamSettings, setStreamSettings] = useState({});
    const [clientSettings, setClientSettings] = useState({});

    useEffect(() => {
        setStreamSettings(streamSettingsStore);
    }, [streamSettingsStore]);
    useEffect(() => {
        setClientSettings(storedClientSettings)
    }, [storedClientSettings])

    const [menuOpen, setMenuOpen] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (menuId) => (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(menuId);
    };

    const closeMenu = () => {
        setMenuOpen("");
    };

    const sliderOpenMenu = (menuId) => (e) => {
        if(menuOpen === "") {
            setAnchorEl(e.currentTarget);
            setMenuOpen(menuId);
        }
        else{
            closeMenu();
        }
    };

    const setStreamSettingsValue = (name, value) => {
        const updatedSettings = {...streamSettings, [name]: value};
        setStreamSettings(updatedSettings);
        dispatch(setStreamConfig(updatedSettings));
        closeMenu();
    };

    const parseJsonAndSetValue =( name , value ) => {
        setStreamSettingsValue(name,JSON.parse(value))
    }

    const setStreamSettingNumbers = (name, value) => {
        value = value != null && value !== "" && !isNaN(value) ? parseInt(value) : 0;
        const updatedSettings = { ...streamSettings, [name]: value };
        setStreamSettings(updatedSettings);
        dispatch(setStreamConfig(updatedSettings));
        closeMenu();
    };

    const setClientSettingsValue = (name, value) => {
        const updatedSettings = {...clientSettings, [name]: value};
        setClientSettings(updatedSettings);
        dispatch(setClientConfig(updatedSettings));
        closeMenu();
    };

    const setClientSettingsValueKeepMenuOpen = (name, value) => {
        const updatedSettings = {...clientSettings, [name]: value};
        setClientSettings(updatedSettings);
        dispatch(setClientConfig(updatedSettings));
    };

    const setBitrateValue = (name, value) => {
        const updatedSettings = {...streamSettings, [name]: value * (1024 * 1024)};
        setStreamSettings(updatedSettings);
        dispatch(setStreamConfig(updatedSettings));
    };

    const advanceMenu = {
        video:
            [
                {
                    menuType: 'MENU',
                    menuName:
                        'Video Resolution',
                    menuDescription:
                        "Optimize your viewing experience by adjusting the screen resolution. Choose a resolution that best suits your screen size and visual preferences.",
                    menuCurrValue:streamSettings.resolution && streamSettings.resolution.width ? JSON.stringify({width : streamSettings.resolution?.width , height:streamSettings.resolution?.height})  : JSON.stringify({width: 1280, height: 720}),
                    menuItems:
                        [
                            {label: "1280 x 720", value: JSON.stringify({width: 1280, height: 720})},
                            {label: "1920 x 1080", value: JSON.stringify({width: 1920, height: 1080})},
                            {label: "2560 x 1440", value: JSON.stringify({width: 2560, height: 1440})},
                            {label: "3840 x 2160", value: JSON.stringify({width: 3840, height: 2160})}
                        ],
                    settingsName:
                        'resolution',
                    functionName:
                    parseJsonAndSetValue
                },
                {
                    menuType: 'MENU',
                    menuName:
                        'Framerate',
                    menuDescription:
                        "Control the number of frames per second that are displayed on a screen in a given amount of time",
                    menuCurrValue:
                        `${streamSettings.framerate || ''}`,
                    menuItems:
                        [
                            {label: "30 fps", value: 30},
                            {label: "60 fps", value: 60},
                            {label: "120 fps", value: 120},
                        ],
                    settingsName:
                        'framerate',
                    functionName:
                    setStreamSettingsValue
                },
                {
                    menuType: 'MENU',
                    menuName:
                        'Codec',
                    menuDescription:
                        "Control the codec used for streaming",
                    menuCurrValue:
                        `${streamSettings.codec || ''}`,
                    menuItems:
                        [
                            {label: "H264", value: "h264"},
                            {label: "HEVC", value: "hevc"}
                        ],
                    settingsName:
                        'codec',
                    functionName:
                    setStreamSettingsValue
                },
                {
                    menuType: 'MENU',
                    menuName:
                        'Color',
                    menuDescription:
                        "Control the color format used for streaming",
                    menuCurrValue:
                        `${streamSettings.color_format || ''}`,
                    menuItems:
                        [
                            {label: "RGB", value: "rgb"},
                            {label: "AYUV", value: "ayuv"},
                            {label: "NV12", value: "nv12"}
                        ],
                    settingsName:
                        'color_format',
                    functionName:
                    setStreamSettingsValue
                },
                {
                    menuType: 'SLIDER',
                    menuName:
                        'Bitrate',
                    menuDescription:
                        "Max Mb used while game arena",
                    scrollLimits : {min:0.5 , max : 25 , step:0.5},
                    menuCurrValue: `${streamSettings.bitrate / (1024 * 1024)} mbps`,
                    sliderValue : streamSettings.bitrate / (1024 * 1024),
                    settingsName:
                        'bitrate',
                    functionName:   setBitrateValue
                },
                {
                    menuType: 'MENU',
                    menuName: 'Display Monitor',
                    menuDescription: "Select which monitor you want to stream",
                    menuCurrValue: `${streamSettings.output_idx || 0}`,
                    menuItems:
                        [
                            {label: "Primary", value: 0},
                            {label: "Secondary", value: 1},
                        ],
                    settingsName:'output_idx',
                    functionName: setStreamSettingsValue
                },
                {
                    menuType: 'TEXT',
                    menuName: 'Display Adapter',
                    menuDescription: "Select the GPU you want to use to stream",
                    menuCurrValue: `${streamSettings.adapter_idx}`,
                    menuItems: [],
                    settingsName: 'adapter_idx',
                    functionName: setStreamSettingNumbers
                },
                {
                    menuType: 'CHECKBOX',
                    menuName: 'Change Resolution',
                    menuDescription: "Change display resolution of source monitor as per above selected resolution",
                    menuCurrValue: streamSettings.change_resolution,
                    menuItems: [],
                    settingsName: 'change_resolution',
                    functionName: setStreamSettingsValue
                }
            ],
        client: [
            {
                menuType: 'TEXT',
                menuName: 'Codec String',
                menuDescription: "Select the preferred codec string for video stream, compatibility and performance across different devices. Use compatible string along with codec",
                menuCurrValue: `${streamSettings.codec_string}`,
                menuItems: [],
                settingsName: 'codec_string',
                functionName: setStreamSettingsValue
            },
            {
                menuType: 'MENU',
                menuName: 'Hardware Acceleration',
                menuDescription: "Opt for hardware decoding to reduce resource consumption. Use software decoding only if your experiencing lag or artifacts",
                menuCurrValue: streamSettings.hardware_acceleration,
                menuItems: [
                    {label: "Hardware", value: "prefer-hardware"},
                    {label: "Software", value: "prefer-software"}
                ],
                settingsName: 'hardware_acceleration',
                functionName: setStreamSettingsValue
            },

            {
                menuType: 'CHECKBOX',
                menuName: 'Paint Immediately',
                menuDescription: "Alternative form of rendering (Experimental feature) // add batch ",
                menuCurrValue: clientSettings.immediate_paint,
                menuItems: [],
                settingsName: 'immediate_paint',
                functionName: setClientSettingsValue
            }
        ],
        input: [
            {
                menuType: 'MENU',
                menuName: 'Mouse Optimized for',
                menuDescription: "Enhance your gaming experience without a secondary mouse. Show the cursor in desktop mode and hide it in gaming mode",
                menuCurrValue: `${clientSettings.cursor_capture||false}`,
                menuItems: [
                    {label: "Gaming", value: true},
                    {label: "Desktop", value: false}
                ],
                settingsName: 'cursor_capture',
                functionName: setClientSettingsValue
            },
            {
                menuType: 'SLIDER',
                menuName: 'Mouse Sensitivity',
                menuDescription: "Fine-tune your cursor's responsiveness , Customize to to match your personal needs",
                menuCurrValue: `${clientSettings.movement_multiplier}x`,
                sliderValue: clientSettings.movement_multiplier,
                settingsName: 'movement_multiplier',
                scrollLimits : {min:0.5 , max : 5 , step:0.01},
                functionName: setClientSettingsValueKeepMenuOpen
            },
            {
                menuType: 'SLIDER',
                menuName: 'Scroll Sensitivity',
                menuDescription: "Fine-tune your scroll responsiveness , Customize to to match your personal needs",
                menuCurrValue: `${clientSettings.scroll_multiplier}x`,
                sliderValue : clientSettings.scroll_multiplier,
                scrollLimits : {min:0.5 , max : 5 , step:0.01},
                settingsName: 'scroll_multiplier',
                functionName: setClientSettingsValueKeepMenuOpen
            },
            {
                menuType: 'MENU',
                menuName: 'Keyboard Type',
                menuDescription: "Handle cross Operating system keyboard interaction, Select client keyboard type .Let you swap CMD and CTRL key for Macos. Windows as default",
                menuCurrValue: `${clientSettings.client_Keyboard_type||''}`,
                menuItems: [
                    {label: "Windows", value: 'Windows'},
                    {label: "MacOS", value: 'MacOS'}
                ],
                settingsName: 'client_Keyboard_type',
                functionName: setClientSettingsValue
            },
        ],
        gamepad:[
            {
                menuType: 'CHECKBOX',
                menuName: 'Auto-Connect Gamepad',
                menuDescription: "Auto connect previously connection gamepad on start",
                menuCurrValue: clientSettings.gamepad_autoconnect,
                settingsName: 'gamepad_autoconnect',
                functionName: setClientSettingsValue
            }
        ]
    }

    return (
        <>
            {advanceMenu[settingType].map(settingsCardOption => (

                <SettingsCard
                    key={settingsCardOption.menuName}
                    title={settingsCardOption.menuName}
                    description={settingsCardOption.menuDescription}
                    settings={{displayValue: settingsCardOption.menuCurrValue}}
                    menuType={settingsCardOption.menuType}
                    menuItems={settingsCardOption.menuItems}
                    menuOpen={menuOpen === settingsCardOption.menuName}
                    scrollLimits={settingsCardOption.scrollLimits}
                    anchorEl={anchorEl}
                    sliderValue={settingsCardOption.sliderValue}
                    openMenu={settingsCardOption.menuType === 'SLIDER' ? sliderOpenMenu(settingsCardOption.menuName) : openMenu(settingsCardOption.menuName)}
                    closeMenu={closeMenu}
                    setSettingsValue={(value) => settingsCardOption.functionName(settingsCardOption.settingsName, value)}
                />
            ))}
        </>
    );
};

export default AdvanceSettings;