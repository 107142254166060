import BaseLayout from "./components/AppLayout/BaseLayout";
import Login from "./pages/Login/Login";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import Signup from "./pages/Signup/Signup";
import StreamClient from "./components/StreamClient/StreamClient";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";

function App() {

  return (<BaseLayout>
      <Routes>
        <Route path={"/login"} element={<Login/>}/>
        <Route path={"/forgot-password"} element={<ForgotPassword/>}/>
        <Route path={"/reset-password"} element={<ResetPassword/>}/>
        <Route path={"/signup"} element={<Signup/>}/>
        <Route path={"/stream/:deviceId"} element={<StreamClient/>}/>
        <Route path={"/*"} element={<Home/>}
        />
      </Routes>
    </BaseLayout>

  );
}

export default App;
